<!-- Loading GIF Panel -->
<div #loadingDiv class="loadingDiv" *ngIf="isLoading">
  <img src="/assets/images/Forensic_Notes_Logo.png" class="loadingLogo" tooltip="Loading Forensic Notes" />
  <img src="/assets/images/loading-gif-png-5.gif" class="loadingAppGif" tooltip="Loading Forensic Notes" />
</div>

<!-- Main App Display -->
<mat-toolbar class="mat-elevation-z8 navbar">
  <ng-container *ngIf="!(notShowLayout$ | async)">
    <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()" >
      <mat-icon *ngIf="!sidenav.opened">
        menu
      </mat-icon>
      <mat-icon *ngIf="sidenav.opened">
        close
      </mat-icon>
    </button>
  </ng-container>

  <div id="service-worker-events"></div>
  <div class="navContainer">
    <div class="logoContainer">
      <img src="assets/images/logo.png" />
    </div>
    <div class="rightItems">

      <div class="notification show-notification" (click)="onShowNotification($event)">
        <div class="notification-count">
          <div class="b-icon"></div>
          <span *ngIf="nCount> 0" class="e-badge e-badge-success e-badge-overlap e-badge-notification
              e-badge-circle">
            {{ nCount }}
          </span>
        </div>
      </div>
      <!-- <div class="menu show-dropdowne custom-dropdown" -->
      <div (click)="showMenu()"><span class="custom-dots"></span>
      </div>

    </div>
  </div>
</mat-toolbar>

<mat-sidenav-container [class.dialogDrag-section]="!(notShowLayout$ | async)">
  <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8" [opened]="!(notShowLayout$ | async) && sidenav.opened">
    <button mat-button class="mat-icon-menu-button" [routerLink]="['/']" (click)="closeSideMenuIfMobile()">
      <mat-icon>home</mat-icon>
      <span>Home</span>
    </button>
    <button mat-button class="mat-icon-menu-button" [routerLink]="['/notebooks/my-notebooks']"
      (click)="closeSideMenuIfMobile()">
      <mat-icon>person</mat-icon>
      <span>My Notebooks</span>
    </button>
    <!-- Show Team Notebooks Only for Group Admin -->
    <button mat-button class="mat-icon-menu-button" [routerLink]="['/notebooks/team-notebooks']"
      *ngIf="showTeamNotebooksMenu" (click)="closeSideMenuIfMobile()">
      <mat-icon>groups</mat-icon>
      <span>Team Notebooks</span>
    </button>
    <!-- Pass Custom Project Naming to Project Page via state-->
    <button mat-button class="mat-icon-menu-button" [routerLink]="['/cms/']" (click)="closeSideMenuIfMobile()" *ngIf="this.showCMS">
      <mat-icon>folder</mat-icon>
      <ng-container *ngIf="customProjectNaming.masterName">
        <span>{{customProjectNaming.masterName}}s</span>
      </ng-container>
      <ng-container *ngIf="!customProjectNaming.masterName">
        <span>...</span>
      </ng-container>
    </button>
    <button mat-button class="mat-icon-menu-button" [routerLink]="['/entities']" (click)="closeSideMenuIfMobile()">
      <mat-icon>fingerprint</mat-icon>
      <span>Entities</span>
    </button>
    <mat-divider class="matDivider"></mat-divider>
    <!-- <button mat-button class="mat-icon-menu-button" [routerLink]="['/work-requests/']" (click)="closeSideMenuIfMobile()">
      <mat-icon>home_repair_service</mat-icon>
      <span>Work Requests</span>
    </button> -->
    <!-- <button mat-button class="mat-icon-menu-button" [routerLink]="['/incident-numbers/']" (click)="closeSideMenuIfMobile()">
      <mat-icon>stream</mat-icon>
      <span>{{adminCmsSettingsModel.incidentOrFileNameDisplayed}}s</span>
    </button> -->
    <button mat-button class="mat-icon-menu-button" [routerLink]="['/contacts/']" (click)="closeSideMenuIfMobile()">
      <mat-icon>contact_mail</mat-icon>
      <span>Contacts</span>
    </button>
    <button mat-button class="mat-icon-menu-button" [routerLink]="['/asset-management/']"
      (click)="closeSideMenuIfMobile()">
      <mat-icon>content_paste</mat-icon>
      <span>Assets</span>
    </button>

    <mat-divider class="matDivider"></mat-divider>

    <button mat-button class="mat-icon-menu-button" onClick="window.open('https://www.forensicnotes.com/help/')">
      <mat-icon>quiz</mat-icon>
      Help
      <mat-icon class="icon-external">open_in_new</mat-icon>
    </button>
    <button mat-button class="mat-icon-menu-button" [routerLink]="['/report-a-bug']" (click)="closeSideMenuIfMobile()">
      <mat-icon>bug_report</mat-icon>
      Report a Bug
    </button>
    <div *ngIf="securityUtils.isServiceWorkerCacheEnabled() == false">
      <mat-divider class="matDivider"></mat-divider>
      <button mat-button class="mat-icon-menu-button" [routerLink]="['/clientSideCaching']"
        (click)="closeSideMenuIfMobile()">
        <mat-icon>cloud_off</mat-icon>
        TURN ON<br>
        Client-Side Caching<br>
        - Offline Capabilities<br>
        - Increased Speed
      </button>
    </div>
    <mat-divider class="matDivider"></mat-divider>
    <button mat-button class="mat-icon-menu-button" [routerLink]="['/ai-tools']"
      (click)="closeSideMenuIfMobile()">
      <img class="png-mat-icon" src="/assets/images/chatgpt-white-88.png">
      AI Tools
    </button>
    <div *ngIf="securityUtils.isServiceWorkerCacheEnabled()">
      <mat-divider class="matDivider"></mat-divider>
      <button mat-button class="mat-icon-menu-button" [routerLink]="['/clientSideCaching']"
        (click)="closeSideMenuIfMobile()">
        <mat-icon>cloud_on</mat-icon>
        Cache Settings
      </button>
    </div>
    <div class="timezoneWarning hand" *ngIf="isTheSameTimeZoneOffset == false" (click)="CheckProfileAndSetup(true)"
      title="Click to re-check">
      <b>Warning:</b><br>
      Your device's Timezone is different than your profile Timezone setting.<br>
      You will NOT be able to adjust the Date/Times of notes until this is corrected.<br>
      <br>
      For further information,<br>
      please contact support.
    </div>
  </mat-sidenav>
  <!-- MAIN CONTENT -->
  <mat-sidenav-content>
    <div>
      <router-outlet></router-outlet>
      <div class="offlineOverlay" *ngIf="!(isOnline$ | async)">
        <mat-icon [inline]="true" class="offlineIcon">cloud_off</mat-icon>Network Offline
      </div>
    </div>
  </mat-sidenav-content>
  <!-- (end of) MAIN CONTENT -->
</mat-sidenav-container>

<!-- Menu Dialog -->
<ejs-dialog id="ejDialogheadMenu" #ejDialogheadMenu header="Options" showCloseIcon='true'
  (overlayClick)="onOverlayClickheadMenu()" width="300px" [visible]="false" [animationSettings]='animationSettings'
  [target]="dialogDragTarget" [allowDragging]='allowDialogDragging' [enableResize]='false' isModal="true">
  <ng-template #content>
    <button mat-button class="mat-icon-menu-button" (click)="goSetting(settingApp)">
      <mat-icon>settings</mat-icon>
      Profile & Settings
    </button>
    <hr>
    <button mat-button class="mat-icon-menu-button" (click)="updateApp()">
      <mat-icon>system_update</mat-icon>
      Get Latest Version
    </button>
    <button mat-button class="mat-icon-menu-button" (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      Logout
    </button>
    <p class="disclaimer center">
      Version: {{curentVersion$ | async}}
    </p>

  </ng-template>
</ejs-dialog>

<!-- Notification Dialog -->
<ejs-dialog id="dialogNotification" header="Notifications" showCloseIcon="true" #ejDialogNotification
  (overlayClick)="onOverlayClickNotification()" width="250px" [visible]="false" class="notifications"
  [position]='topPosition' [animationSettings]='animationSettings' [target]="dialogDragTarget"
  [allowDragging]='allowDialogDragging' [enableResize]='false' isModal="true">
  <div id="Allnotification">
    <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)" maxSize="5"
      previousLabel="Prev." nextLabel="Next">
    </pagination-controls>
    <div class="notification-filter-group">
      <a class="notification-read notification-filter-unread" (click)="unreadNotification()">Filter by Unread</a>
      <a class="notification-read" (click)="readAllNotification()">Mark All as Read</a>
    </div>
    <a class="refresh-noti" id="Refresh" titile="Refresh" (click)="refreshnotification(fullcount)"><i
        class="fas fa-sync"></i>
    </a>
    <div class="main-n-list">
      <div #pageContent class="notification-list"
        *ngFor="let notification of (allNotification | paginate: { itemsPerPage: 3, currentPage: p })">
        <!-- UNRead -->
        <div (click)="markAsReadOrUnread(notification)" title="Click to Mark as Read"
          *ngIf="notification.dateTimeNotificationRead === undefined" id="ntf_{{ notification.notificationID }}">
          <div class="n-15">
            <div class="like-icon"></div>
          </div>
          <div class="n-85">
            <span class="n-heading">{{ notification.title }}</span> <br />
            <div [innerHTML]="notification.notificationMessage" class="notificationText"></div>
            <div class="notificationDate">Created: {{notification.dateNotificationCreatedStr}} UTC</div>
          </div>
        </div>
        <!-- READ -->
        <div (click)="markAsReadOrUnread(notification)" *ngIf="notification.dateTimeNotificationRead !== undefined"
          id="ntf_{{ notification.notificationID }}" class="notifiaction-unread">
          <div class="n-15">
            <div class="like-icon"></div>
          </div>
          <div class="n-85">
            <span class="n-heading">{{ notification.title }}</span> <br />
            <div [innerHTML]="notification.notificationMessage" class="notificationText"></div>
            <div class="notificationDate">Created: {{notification.dateNotificationCreatedStr}} UTC</div>
            <div class="notificationDate">Read: {{notification.dateTimeNotificationReadStr}} UTC</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="ViewAllNotification" class="view-all-notification-group">
    <img src="/assets/images/forensicNotification.png" class="notification-img" />
    <p>No Unread Notifications</p>
    <p>Click <a (click)="viewAllNotification()" class="notification-text">View All</a> to view all of your notifications
    </p>
  </div>
</ejs-dialog>


<app-download-confirmation-dialog #ejDialogConfirmDownload></app-download-confirmation-dialog>

<app-setting #ejDialogSettings></app-setting>

<app-case-management-incident-numbers #componentIncidents></app-case-management-incident-numbers>

<app-account-setup #componentAccountSetup></app-account-setup>

<app-encryption-password #componentEncrytionPassword></app-encryption-password>

<ng-container #dynamicDialogComponents></ng-container>