// DEV Server
export const environment = {
  production: true,
  hideConsoleLog: true,
  AdvanceMode: "https://legacy.forensicnotes.com",
  LoginUrl: "https://forensicnotes.b2clogin.com/forensicnotes.com/oauth2/v2.0/authorize?p=b2c_1_augtemp2023&client_id=03424daf-3f37-420a-ae6c-b8b371627502",
  FAQ: 'http://forensicnotes.com/faqs',
  RequireAuthentication: true,      // Change to false if local and AUTH not required.
  ChunkSize: 1048576,
  notebookPageSize: 5, //,
  showCMS: true,
  // App Insights
  insightsInstrumentationKey: '2b1aff0d-4879-4ef9-b3e4-1ea719c1f2ac',
  // auth-config settings...
  clientID: '03424daf-3f37-420a-ae6c-b8b371627502',       // NOTE - Make sure ID matches ID in LoginURL above
  authorityDomain: 'forensicnotes.b2clogin.com',
  signUpSignIn: 'b2c_1_augtemp2023',
  editProfile: 'B2C_1_edit_profile',

  authorityLogin: 'https://forensicnotes.b2clogin.com/forensicnotes.com/b2c_1_augtemp2023',
  authorityEdit: 'https://forensicnotes.b2clogin.com/forensicnotes.com/B2C_1_edit_profile',
  
  postLogoutRedirectUri: "https://www.forensicnotes.com",


   // API's
   apiBaseUrlEndpoint: 'https://fn-notesapi-prod-b2c1.thankfulforest-f6f12e33.eastus.azurecontainerapps.io',
   assetsApiEndPoint: 'https://crmapi-prd-b2c1.thankfulforest-f6f12e33.eastus.azurecontainerapps.io',
   caseManagementApiEndpoint: 'https://crmapi-prd-b2c1.thankfulforest-f6f12e33.eastus.azurecontainerapps.io',
   organizationApiEndpoint: 'https://crmapi-prd-b2c1.thankfulforest-f6f12e33.eastus.azurecontainerapps.io',

  // ----------------------------------------------------------
  // WebSocket - SignalR - Does not work via Front Door
  // ----------------------------------------------------------
  apiSignalRSocketApiEndPoint: 'https://fn-notesapi-prod-b2c1.thankfulforest-f6f12e33.eastus.azurecontainerapps.io',

  // Scope
  scopes: 'https://forensicnotes.com/d57dc61c-774d-4d00-9e45-7096d058a687/access_as_user',
  

};

