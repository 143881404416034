import { NgModule, InjectionToken } from '@angular/core';
import { environment } from '../environments/environment';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');


export class AppConfig {
  uploadchunksize!: number;
  requireAuthentication!: boolean;
  loginUrl!: string;
  postLogoutRedirectUri: string;
  notebookPageSize!: number;
  AdvanceMode!: string;
  FAQ!: string;
  insightsInstrumentationKey!: string;
  showCMS!: boolean;
}
export const APP_DI_CONFIG: AppConfig = {
  loginUrl: environment.LoginUrl,
  postLogoutRedirectUri: environment.postLogoutRedirectUri,
  requireAuthentication: environment.RequireAuthentication,
  uploadchunksize: environment.ChunkSize,
  notebookPageSize: environment.notebookPageSize,
  AdvanceMode: environment.AdvanceMode,
  FAQ: environment.FAQ,
  insightsInstrumentationKey: environment.insightsInstrumentationKey,
  showCMS: environment.showCMS,
};

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: APP_DI_CONFIG
  }]
})
export class AppConfigModule { }
